var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"aboutUsValidation"},[_c('b-form',{staticClass:"form-container",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-col',[_c('h4',{staticClass:"mb-2"},[_vm._v("Konfiguracja")]),_c('b-form-group',{attrs:{"label":"Język","label-for":"v-language-select"}},[_c('v-select',{attrs:{"id":"v-language-select","label":"value","placeholder":"Wybierz język","clearable":false,"deselectFromDropdown":false,"options":_vm.languages},on:{"input":_vm.fetchByLocale},model:{value:(_vm.form.lang),callback:function ($$v) {_vm.$set(_vm.form, "lang", $$v)},expression:"form.lang"}})],1),_c('b-form-group',[_c('template',{slot:"label"},[_c('span',{attrs:{"label-for":"v-image-title"}},[_vm._v("Tytuł zdjęcia")]),_c('feather-icon',{staticClass:"info-icon",attrs:{"icon":"InfoIcon"},on:{"click":function($event){return _vm.showTourTip('title')}}})],1),_c('validation-provider',{attrs:{"name":"Tytuł zdjęcia","rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-image-title","placeholder":"Tytuł zdjęcia","disabled":!_vm.form.lang},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],2),_c('b-form-group',[_c('template',{slot:"label"},[_c('span',{attrs:{"label-for":"v-image"}},[_vm._v("Zdjęcie nagłówkowe")]),_c('feather-icon',{staticClass:"info-icon",attrs:{"icon":"InfoIcon"},on:{"click":function($event){return _vm.showTourTip('title-image')}}})],1),_c('b-button',{attrs:{"disabled":!_vm.form.lang},on:{"click":function($event){return _vm.toggleShowModal('title_image')}}},[_vm._v(" Wybierz zdjęcie ")]),_c('validation-provider',{attrs:{"name":"Zdjęcie nagłówkowe","rules":"required|size:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.files.title_image)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.title_image.file),expression:"files.title_image.file"}],attrs:{"type":"hidden","name":"Zdjęcie nagłówkowe"},domProps:{"value":(_vm.files.title_image.file)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files.title_image, "file", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.title_image),expression:"files.title_image"}],attrs:{"type":"hidden","name":"Zdjęcie nagłówkowe"},domProps:{"value":(_vm.files.title_image)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files, "title_image", $event.target.value)}}}),(_vm.showImagesModal.title_image)?_c('ImageSelector',{attrs:{"files":_vm.filesArray,"show-modal":_vm.showImagesModal.title_image},on:{"closeModal":function($event){return _vm.toggleShowModal('title_image')},"submit":function (fileList) { return _vm.handleFile(fileList[0], 'title_image'); }}}):_vm._e(),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.files.title_image)?_c('ImagePreview',{attrs:{"image":_vm.files.title_image},on:{"deleteImage":function () { return _vm.handleDelete('title_image'); }}}):_vm._e()],2)],1),_c('b-col',[_c('h4',{staticClass:"mt-3 mb-2"},[_vm._v("Treść")]),_c('b-form-group',[_c('template',{slot:"label"},[_c('span',{attrs:{"label-for":"v-header-note"}},[_vm._v("Górna notka")]),_c('feather-icon',{staticClass:"info-icon",attrs:{"icon":"InfoIcon"},on:{"click":function($event){return _vm.showTourTip('header')}}})],1),_c('validation-provider',{attrs:{"name":"Górna notatka","rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-header-note","placeholder":"Górna notka","disabled":!_vm.form.lang},model:{value:(_vm.form.header),callback:function ($$v) {_vm.$set(_vm.form, "header", $$v)},expression:"form.header"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],2)],1),_c('b-col',[_c('b-form-group',{staticClass:"about-us-file-container"},[_c('template',{slot:"label"},[_c('span',{attrs:{"label-for":"v-image-top"}},[_vm._v("Zdjęcie górne")]),_c('feather-icon',{staticClass:"info-icon",attrs:{"icon":"InfoIcon"},on:{"click":function($event){return _vm.showTourTip('top-image')}}})],1),_c('b-button',{attrs:{"disabled":!_vm.form.lang},on:{"click":function($event){return _vm.toggleShowModal('top_image')}}},[_vm._v(" Wybierz zdjęcie ")]),_c('validation-provider',{attrs:{"name":"Zdjęcie górne","rules":"required|size:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.files.top_image)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.top_image.file),expression:"files.top_image.file"}],attrs:{"type":"hidden","name":"Zdjęcie górne"},domProps:{"value":(_vm.files.top_image.file)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files.top_image, "file", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.top_image),expression:"files.top_image"}],attrs:{"type":"hidden","name":"Zdjęcie górne"},domProps:{"value":(_vm.files.top_image)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files, "top_image", $event.target.value)}}}),(_vm.showImagesModal.top_image)?_c('ImageSelector',{attrs:{"files":_vm.filesArray,"show-modal":_vm.showImagesModal.top_image},on:{"closeModal":function($event){return _vm.toggleShowModal('top_image')},"submit":function (fileList) { return _vm.handleFile(fileList[0], 'top_image'); }}}):_vm._e(),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.files.top_image)?_c('ImagePreview',{attrs:{"image":_vm.files.top_image},on:{"deleteImage":function () { return _vm.handleDelete('top_image'); }}}):_vm._e()],2),_c('b-form-group',{staticClass:"about-us-file-container"},[_c('template',{slot:"label"},[_c('span',{attrs:{"label-for":"v-image-left"}},[_vm._v("Zdjęcie lewe")]),_c('feather-icon',{staticClass:"info-icon",attrs:{"icon":"InfoIcon"},on:{"click":function($event){return _vm.showTourTip('left-image')}}})],1),_c('b-button',{attrs:{"disabled":!_vm.form.lang},on:{"click":function($event){return _vm.toggleShowModal('middle_image')}}},[_vm._v(" Wybierz zdjęcie ")]),_c('validation-provider',{attrs:{"name":"Zdjęcie lewe","rules":"required|size:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.files.middle_image)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.middle_image.file),expression:"files.middle_image.file"}],attrs:{"type":"hidden","name":"Zdjęcie lewe"},domProps:{"value":(_vm.files.middle_image.file)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files.middle_image, "file", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.middle_image),expression:"files.middle_image"}],attrs:{"type":"hidden","name":"Zdjęcie lewe"},domProps:{"value":(_vm.files.middle_image)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files, "middle_image", $event.target.value)}}}),(_vm.showImagesModal.middle_image)?_c('ImageSelector',{attrs:{"files":_vm.filesArray,"show-modal":_vm.showImagesModal.middle_image},on:{"closeModal":function($event){return _vm.toggleShowModal('middle_image')},"submit":function (fileList) { return _vm.handleFile(fileList[0], 'middle_image'); }}}):_vm._e(),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.files.middle_image)?_c('ImagePreview',{attrs:{"image":_vm.files.middle_image},on:{"deleteImage":function () { return _vm.handleDelete('middle_image'); }}}):_vm._e()],2),_c('b-form-group',{staticClass:"about-us-file-container"},[_c('template',{slot:"label"},[_c('span',{attrs:{"label-for":"v-image-right"}},[_vm._v("Zdjęcie prawe")]),_c('feather-icon',{staticClass:"info-icon",attrs:{"icon":"InfoIcon"},on:{"click":function($event){return _vm.showTourTip('right-image')}}})],1),_c('b-button',{attrs:{"disabled":!_vm.form.lang},on:{"click":function($event){return _vm.toggleShowModal('bottom_image')}}},[_vm._v(" Wybierz zdjęcie ")]),_c('validation-provider',{attrs:{"name":"Zdjęcie prawe","rules":"required|size:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.files.bottom_image)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.bottom_image.file),expression:"files.bottom_image.file"}],attrs:{"type":"hidden","name":"Zdjęcie prawe"},domProps:{"value":(_vm.files.bottom_image.file)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files.bottom_image, "file", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.bottom_image),expression:"files.bottom_image"}],attrs:{"type":"hidden","name":"Zdjęcie prawe"},domProps:{"value":(_vm.files.bottom_image)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files, "bottom_image", $event.target.value)}}}),(_vm.showImagesModal.bottom_image)?_c('ImageSelector',{attrs:{"files":_vm.filesArray,"show-modal":_vm.showImagesModal.bottom_image},on:{"closeModal":function($event){return _vm.toggleShowModal('bottom_image')},"submit":function (fileList) { return _vm.handleFile(fileList[0], 'bottom_image'); }}}):_vm._e(),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.files.bottom_image)?_c('ImagePreview',{attrs:{"image":_vm.files.bottom_image},on:{"deleteImage":function () { return _vm.handleDelete('bottom_image'); }}}):_vm._e()],2)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Treść"}},[_c('validation-provider',{attrs:{"name":"Treść","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{attrs:{"id":"v-content","options":_vm.editorOption,"disabled":!_vm.form.lang},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('h4',{staticClass:"mt-3 mb-2"},[_vm._v("Oś czasu")]),_c('b-form-group',{attrs:{"label":"Oś czasu - wstęp"}},[_c('validation-provider',{attrs:{"name":"Oś czasu - wstęp","rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-introduce","placeholder":"Oś czasu - wstęp","disabled":!_vm.form.lang},model:{value:(_vm.form.introduce),callback:function ($$v) {_vm.$set(_vm.form, "introduce", $$v)},expression:"form.introduce"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-form-group',{attrs:{"label":"Oś czasu - elementy"}},[_vm._l((_vm.form.timelines),function(item,index){return _c('b-col',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-form-group',{attrs:{"label":"Rok","label-for":("year-" + (item.id) + "-" + (item.id))}},[_c('validation-provider',{attrs:{"name":("Rok #" + index),"rules":"required|min:4|max:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("year-" + (item.id) + "-" + (item.id)),"type":"number","placeholder":"Podaj rok wydarzenia"},model:{value:(_vm.form.timelines[index].year),callback:function ($$v) {_vm.$set(_vm.form.timelines[index], "year", $$v)},expression:"form.timelines[index].year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Opis","label-for":("desc-" + (item.id) + "-" + (item.id))}},[_c('validation-provider',{attrs:{"name":("Opis #" + index),"rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":("desc-" + (item.id) + "-" + (item.id)),"placeholder":"Opis"},model:{value:(_vm.form.timelines[index].content),callback:function ($$v) {_vm.$set(_vm.form.timelines[index], "content", $$v)},expression:"form.timelines[index].content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger","disabled":index === 0},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Usuń")])],1),_c('b-col',[_c('hr')])],1)}),_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary","disabled":!_vm.form.lang},on:{"click":_vm.addTimelineElement}},[_c('span',[_vm._v("Dodaj element osi czasu")])])],1)],2),_c('b-col',{staticClass:"mt-5"},[_c('h4',{staticClass:"mb-2"},[_vm._v("Dystrybucja za granicę")]),_c('b-form-group',[_c('template',{slot:"label"},[_c('span',{attrs:{"label-for":"v-distribution-text"}},[_vm._v("Tekst")])]),_c('validation-provider',{attrs:{"name":"Tekst dystrybucji","rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-distribution-text","placeholder":"Tekst dystrybucji","disabled":!_vm.form.lang},model:{value:(_vm.form.distribution_text),callback:function ($$v) {_vm.$set(_vm.form, "distribution_text", $$v)},expression:"form.distribution_text"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],2),_c('b-form-group',{staticClass:"about-us-file-container"},[_c('template',{slot:"label"},[_c('span',{attrs:{"label-for":"v-image-distribution"}},[_vm._v("Zdjęcie dystrybucji")])]),_c('b-button',{attrs:{"disabled":!_vm.form.lang},on:{"click":function($event){return _vm.toggleShowModal('distribution_image')}}},[_vm._v(" Wybierz zdjęcie ")]),_c('validation-provider',{attrs:{"name":"Zdjęcie dystrybucji","rules":"required|size:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.files.distribution_image)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.distribution_image.file),expression:"files.distribution_image.file"}],attrs:{"type":"hidden","name":"Zdjęcie dystrybucji"},domProps:{"value":(_vm.files.distribution_image.file)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files.distribution_image, "file", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.distribution_image),expression:"files.distribution_image"}],attrs:{"type":"hidden","name":"Zdjęcie dystrybucji"},domProps:{"value":(_vm.files.distribution_image)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files, "distribution_image", $event.target.value)}}}),(_vm.showImagesModal.distribution_image)?_c('ImageSelector',{attrs:{"files":_vm.filesArray,"show-modal":_vm.showImagesModal.distribution_image},on:{"closeModal":function($event){return _vm.toggleShowModal('distribution_image')},"submit":function (fileList) { return _vm.handleFile(fileList[0], 'distribution_image'); }}}):_vm._e(),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.files.distribution_image)?_c('ImagePreview',{attrs:{"image":_vm.files.distribution_image},on:{"deleteImage":function () { return _vm.handleDelete('distribution_image'); }}}):_vm._e()],2)],1),_c('b-col',{staticClass:"mt-5"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary","disabled":!_vm.form.lang}},[_vm._v(" Zapisz ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }