<template>
  <validation-observer ref="aboutUsValidation">
    <b-form @submit.prevent="validationForm" class="form-container">
      <b-col>
        <h4 class="mb-2">Konfiguracja</h4>
        <b-form-group label="Język" label-for="v-language-select">
          <v-select
            id="v-language-select"
            label="value"
            placeholder="Wybierz język"
            v-model="form.lang"
            :clearable="false"
            :deselectFromDropdown="false"
            :options="languages"
            @input="fetchByLocale" />
        </b-form-group>

        <b-form-group>
          <template slot="label">
            <span label-for="v-image-title">Tytuł zdjęcia</span>
            <feather-icon icon="InfoIcon" class="info-icon" @click="showTourTip('title')" />
          </template>
          <validation-provider #default="{ errors }" name="Tytuł zdjęcia" rules="required|max:150">
            <b-form-input id="v-image-title" placeholder="Tytuł zdjęcia" v-model="form.title" :disabled="!form.lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <template slot="label">
            <span label-for="v-image">Zdjęcie nagłówkowe</span>
            <feather-icon icon="InfoIcon" class="info-icon" @click="showTourTip('title-image')" />
          </template>
          <b-button @click="toggleShowModal('title_image')" :disabled="!form.lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Zdjęcie nagłówkowe" rules="required|size:5000">
            <!-- workaround for validation-provider -->
            <input v-if="files.title_image" type="hidden" name="Zdjęcie nagłówkowe" v-model="files.title_image.file" />
            <input v-else type="hidden" name="Zdjęcie nagłówkowe" v-model="files.title_image" />

            <ImageSelector
              v-if="showImagesModal.title_image"
              :files="filesArray"
              :show-modal="showImagesModal.title_image"
              @closeModal="toggleShowModal('title_image')"
              @submit="(fileList) => handleFile(fileList[0], 'title_image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.title_image"
            :image="files.title_image"
            @deleteImage="() => handleDelete('title_image')" />
        </b-form-group>
      </b-col>

      <b-col>
        <h4 class="mt-3 mb-2">Treść</h4>
        <b-form-group>
          <template slot="label">
            <span label-for="v-header-note">Górna notka</span>
            <feather-icon icon="InfoIcon" class="info-icon" @click="showTourTip('header')" />
          </template>
          <validation-provider #default="{ errors }" name="Górna notatka" rules="required|max:150">
            <b-form-input id="v-header-note" placeholder="Górna notka" v-model="form.header" :disabled="!form.lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group class="about-us-file-container">
          <template slot="label">
            <span label-for="v-image-top">Zdjęcie górne</span>
            <feather-icon icon="InfoIcon" class="info-icon" @click="showTourTip('top-image')" />
          </template>
          <b-button @click="toggleShowModal('top_image')" :disabled="!form.lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Zdjęcie górne" rules="required|size:5000">
            <!-- workaround for validation-provider -->
            <input v-if="files.top_image" type="hidden" name="Zdjęcie górne" v-model="files.top_image.file" />
            <input v-else type="hidden" name="Zdjęcie górne" v-model="files.top_image" />

            <ImageSelector
              v-if="showImagesModal.top_image"
              :files="filesArray"
              :show-modal="showImagesModal.top_image"
              @closeModal="toggleShowModal('top_image')"
              @submit="(fileList) => handleFile(fileList[0], 'top_image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.top_image"
            :image="files.top_image"
            @deleteImage="() => handleDelete('top_image')" />
        </b-form-group>

        <b-form-group class="about-us-file-container">
          <template slot="label">
            <span label-for="v-image-left">Zdjęcie lewe</span>
            <feather-icon icon="InfoIcon" class="info-icon" @click="showTourTip('left-image')" />
          </template>
          <b-button @click="toggleShowModal('middle_image')" :disabled="!form.lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Zdjęcie lewe" rules="required|size:5000">
            <!-- workaround for validation-provider -->
            <input v-if="files.middle_image" type="hidden" name="Zdjęcie lewe" v-model="files.middle_image.file" />
            <input v-else type="hidden" name="Zdjęcie lewe" v-model="files.middle_image" />

            <ImageSelector
              v-if="showImagesModal.middle_image"
              :files="filesArray"
              :show-modal="showImagesModal.middle_image"
              @closeModal="toggleShowModal('middle_image')"
              @submit="(fileList) => handleFile(fileList[0], 'middle_image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.middle_image"
            :image="files.middle_image"
            @deleteImage="() => handleDelete('middle_image')" />
        </b-form-group>

        <b-form-group class="about-us-file-container">
          <template slot="label">
            <span label-for="v-image-right">Zdjęcie prawe</span>
            <feather-icon icon="InfoIcon" class="info-icon" @click="showTourTip('right-image')" />
          </template>
          <b-button @click="toggleShowModal('bottom_image')" :disabled="!form.lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Zdjęcie prawe" rules="required|size:5000">
            <!-- workaround for validation-provider -->
            <input v-if="files.bottom_image" type="hidden" name="Zdjęcie prawe" v-model="files.bottom_image.file" />
            <input v-else type="hidden" name="Zdjęcie prawe" v-model="files.bottom_image" />

            <ImageSelector
              v-if="showImagesModal.bottom_image"
              :files="filesArray"
              :show-modal="showImagesModal.bottom_image"
              @closeModal="toggleShowModal('bottom_image')"
              @submit="(fileList) => handleFile(fileList[0], 'bottom_image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.bottom_image"
            :image="files.bottom_image"
            @deleteImage="() => handleDelete('bottom_image')" />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Treść">
          <validation-provider #default="{ errors }" name="Treść" rules="required">
            <quill-editor id="v-content" v-model="form.value" :options="editorOption" :disabled="!form.lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col>
        <h4 class="mt-3 mb-2">Oś czasu</h4>
        <b-form-group label="Oś czasu - wstęp">
          <validation-provider #default="{ errors }" name="Oś czasu - wstęp" rules="required|max:150">
            <b-form-input
              id="v-introduce"
              placeholder="Oś czasu - wstęp"
              v-model="form.introduce"
              :disabled="!form.lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- Form repeater -->
      <b-form-group label="Oś czasu - elementy">
        <b-col v-for="(item, index) in form.timelines" :id="item.id" :key="item.id" ref="row">
          <!-- Year -->
          <b-form-group label="Rok" :label-for="`year-${item.id}-${item.id}`">
            <validation-provider #default="{ errors }" :name="`Rok #${index}`" rules="required|min:4|max:4">
              <b-form-input
                v-model="form.timelines[index].year"
                :id="`year-${item.id}-${item.id}`"
                type="number"
                placeholder="Podaj rok wydarzenia" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Description -->

          <b-form-group label="Opis" :label-for="`desc-${item.id}-${item.id}`">
            <validation-provider #default="{ errors }" :name="`Opis #${index}`" rules="required|max:150">
              <b-form-textarea
                v-model="form.timelines[index].content"
                :id="`desc-${item.id}-${item.id}`"
                placeholder="Opis" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Remove Button -->

          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-0 mt-md-2"
            :disabled="index === 0"
            @click="removeItem(index)">
            <feather-icon icon="XIcon" class="mr-25" />
            <span>Usuń</span>
          </b-button>

          <b-col>
            <hr />
          </b-col>
        </b-col>

        <b-col>
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            @click="addTimelineElement"
            :disabled="!form.lang">
            <span>Dodaj element osi czasu</span>
          </b-button>
        </b-col>
      </b-form-group>

      <b-col class="mt-5">
        <h4 class="mb-2">Dystrybucja za granicę</h4>

        <b-form-group>
          <template slot="label">
            <span label-for="v-distribution-text">Tekst</span>
          </template>
          <validation-provider #default="{ errors }" name="Tekst dystrybucji" rules="required|max:150">
            <b-form-input
              id="v-distribution-text"
              placeholder="Tekst dystrybucji"
              v-model="form.distribution_text"
              :disabled="!form.lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="about-us-file-container">
          <template slot="label">
            <span label-for="v-image-distribution">Zdjęcie dystrybucji</span>
          </template>
          <b-button @click="toggleShowModal('distribution_image')" :disabled="!form.lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Zdjęcie dystrybucji" rules="required|size:5000">
            <!-- workaround for validation-provider -->
            <input
              v-if="files.distribution_image"
              type="hidden"
              name="Zdjęcie dystrybucji"
              v-model="files.distribution_image.file" />
            <input v-else type="hidden" name="Zdjęcie dystrybucji" v-model="files.distribution_image" />

            <ImageSelector
              v-if="showImagesModal.distribution_image"
              :files="filesArray"
              :show-modal="showImagesModal.distribution_image"
              @closeModal="toggleShowModal('distribution_image')"
              @submit="(fileList) => handleFile(fileList[0], 'distribution_image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.distribution_image"
            :image="files.distribution_image"
            @deleteImage="() => handleDelete('distribution_image')" />
        </b-form-group>
      </b-col>

      <b-col class="mt-5">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" :disabled="!form.lang">
          Zapisz
        </b-button>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';

import FileUploadMixin from '@/mixins/FileUploadMixin.vue';
import NotificationMixin from '@/mixins/NotificationMixin';
import { toolbarSettings } from '@/constants/quill-editor';
import { getLanguages } from '@/constants/languages';

import ImagePreview from '@/components/shared/ImagePreview.vue';
import ImageSelector from '@/components/shared/ImageSelector.vue';

export default {
  mixins: [FileUploadMixin, NotificationMixin],
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  components: {
    quillEditor,
    ImagePreview,
    ImageSelector,
  },

  data() {
    return {
      languages: getLanguages(),
      editorOption: {
        modules: {
          toolbar: toolbarSettings,
        },
      },
      form: this.page,
    };
  },

  created() {
    this.form.lang = {
      lang: 'pl',
      value: 'Polski',
    };
    this.setFileUploadData(
      ['title_image', 'bottom_image', 'middle_image', 'top_image', 'distribution_image'],
      ['title_image', 'bottom_image', 'middle_image', 'top_image', 'distribution_image'],
    );
    this.fetchByLocale();
  },

  watch: {
    async page(newValue) {
      if (this.form.value === newValue) return;

      await Promise.all([
        this.setImage(this.page.title_image, 'title_image'),
        this.setImage(this.page.bottom_image, 'bottom_image'),
        this.setImage(this.page.middle_image, 'middle_image'),
        this.setImage(this.page.top_image, 'top_image'),
        this.setImage(this.page.distribution_image, 'distribution_image'),
      ]);
      const lang = this.form.lang;

      this.form = { ...newValue };
      this.form.lang = lang;
    },
  },

  methods: {
    addTimelineElement() {
      this.form.timelines.push({
        id: new Date().toISOString(),
        year: '',
        content: '',
      });
    },
    removeItem(index) {
      this.$delete(this.form.timelines, index);
    },
    showTourTip(image) {
      this.$swal({
        imageUrl: require(`@/assets/images/tour/about-us/${image}.png`),
        imageWidth: '100%',
        imageHeight: 'auto',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      });
    },
    validationForm() {
      this.$refs.aboutUsValidation.validate().then((success) => {
        if (success) {
          return this.onSubmit();
        }

        this.showFormValidationNotification();
      });
    },
    onSubmit() {
      const data = {
        ...this.form,
        title_image: this.files.title_image,
        bottom_image: this.files.bottom_image,
        middle_image: this.files.middle_image,
        top_image: this.files.top_image,
        distribution_image: this.files.distribution_image,
        lang: this.form.lang.lang,
      };

      this.$emit('onSubmit', data);
    },
    fetchByLocale() {
      this.$emit('fetchByLocale', this.form.lang.lang);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.info-icon {
  transform: translateY(-1px);
  margin-left: 5px;
  cursor: pointer;
}
</style>
