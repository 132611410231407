import axios from '../axios';

export const show = (lang = null) => {
  const params = lang
    ? {
        lang,
      }
    : {};

  return axios.get('/about-us', {
    params,
  });
};

export const submit = (payload) => {
  return axios.post('/about-us', payload);
};
