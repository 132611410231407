<template>
  <b-card ref="formContainer">
    <form-component :page="aboutUs" @onSubmit="onSubmit" @fetchByLocale="fetchAboutUs" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/pages/about-us/FormComponent';
import LoaderMixin from '@/mixins/LoaderMixin.vue';
import NotificationMixin from '@/mixins/NotificationMixin';
import { show, submit } from '@/http/static-pages/about-us';

export default {
  mixins: [LoaderMixin, NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      aboutUs: {
        timelines: [
          {
            year: '',
            content: '',
          },
        ],
      },
      imageUrl: null,
    };
  },

  methods: {
    fetchAboutUs(lang = 'pl') {
      show(lang)
        .then(({ data: { data } }) => {
          if (!data) {
            return;
          }

          this.aboutUs = data;
        })
        .catch(() => {
          this.showErrorNotification(
            'Problem z pobraniem danych',
            'Wystąpił błąd podczas pobierania danych. Skontaktuj się ze swoim developerem.',
          );
        });
    },
    onSubmit(data) {
      const form = new FormData();

      form.append('lang', data.lang);
      form.append('title', data.title);
      form.append('header', data.header);
      form.append('introduce', data.introduce);
      form.append('value', data.value);
      form.append('distribution_text', data.distribution_text);

      form.append('title_image', data.title_image.file, data.title_image.fileName);
      form.append('bottom_image', data.bottom_image.file, data.bottom_image.fileName);
      form.append('top_image', data.top_image.file, data.top_image.fileName);
      form.append('middle_image', data.middle_image.file, data.middle_image.fileName);
      form.append('distribution_image', data.distribution_image.file);

      if (data.timelines?.length) {
        data.timelines.forEach((timeline, index) => {
          form.delete(`timelines[${index}][id]`);
          if (typeof timeline.id !== 'string') {
            form.append(`timelines[${index}][id]`, timeline.id);
          }

          form.append(`timelines[${index}][year]`, timeline.year);
          form.append(`timelines[${index}][content]`, timeline.content);
        });
      }

      this.showLoader(this.$refs.formContainer);

      submit(form)
        .then(({ data: { data } }) => {
          this.aboutUs = data;
          this.showSuccessNotification('Dane zapisane', 'Strona została zaktualizowana.');
        })
        .catch(() => {
          this.showErrorNotification(
            'Problem z zapisaniem danych',
            'Wystąpił błąd podczas zapisywania danych. Skontaktuj się ze swoim developerem.',
          );
        })
        .finally(() => {
          this.hideLoader();
        });
    },
  },
};
</script>
